import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import WebFont from 'webfontloader';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { CacheProvider } from 'rest-hooks';
import App from './app';
import * as analytics from './lib/analytics';

import('./styles/index.css');

// cache history when using hmr to only create it once
const history = ((o, k) => {
  if (o[k]) {
    return o[k];
  }
  o[k] = createBrowserHistory();
  return o[k];
})(window, 'hmrHistoryCache');

WebFont.load({
  google: {
    families: ['Nunito+Sans:300,400,600,700'],
  },
  active() {
    const container = document.getElementById('root');
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(
      <Router history={history} basename={import.meta.env.BASE_URL}>
        <CacheProvider>
          <App />
        </CacheProvider>
      </Router>
    );
  },
});

// Initilize analytics only in production build
if (import.meta.env.PROD) {
  analytics.init();
}
